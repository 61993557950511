import Router from 'next/router';
import { useQuery } from 'react-query';

import { config } from '@jernia/shared/lib/config';

import { useAuthStore } from '../auth';
import { fetchWithAuth } from '../fetch';
import { getCartId } from './shared';
import { useCartStore } from './store';

import { DeliveryMethod } from '@jernia/shared/types/delivery-methods';
import type { Cart } from '@jernia/shared/types/rest/cart';
import { RestError } from '@jernia/shared/types/rest/error';

export function useCart() {
  const { cartId, cartUid, clearCart } = useCartStore();

  return useQuery<Cart | null, RestError>(
    ['cart', cartId, cartUid],
    async () => {
      if (cartId && cartUid) {
        const response = await fetchWithAuth(
          `${config.apiBaseUrl}/users/${cartUid}/carts/${cartId}`
        );

        const result = await response.json();

        if (!response.ok) {
          throw result as RestError;
        }

        return result;
      }

      return null;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: false,

      onError: (error) => {
        const notFound = error.errors.some((err) => err.reason === 'notFound');

        if (notFound) {
          clearCart();
        }

        if (config.site === 'self-checkout') {
          // Log the user out if they don't have a cart. They need to log back
          // in to create a new self checkout cart.
          Router.push('/');
        }
      },
    }
  );
}

export function useDeliveryMethods() {
  const { data: cart } = useCart();
  const { userId } = useAuthStore();

  const cartId = getCartId(cart);

  return useQuery<DeliveryMethod[]>(
    ['deliveryMethods', cartId, userId],
    () => {
      if (cartId && cart?.deliveryAddress) {
        return fetchWithAuth(
          `${config.apiBaseUrl}/users/${userId}/carts/${cartId}/deliverymodes`
        )
          .then((resp) => resp.json())
          .then((result) => result.deliveryModes);
      }

      return [];
    },
    {
      enabled: Boolean(cart?.deliveryAddress) && Boolean(cartId),
    }
  );
}
