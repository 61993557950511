import classNames from 'classnames';
import { animate, motion, useMotionValue } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import useResizeObserver from 'use-resize-observer';

import { CloseRemoveXIcon } from '@jernia/shared/components/icons/icons';

type Props = {
  isOpen: boolean;
  size?: 'large' | 'medium';
  noPadding?: boolean;
  onRequestClose?: () => void;
  onAfterClose?: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
  footer?: React.ReactNode;
  lessHeaderPadding?: boolean;
  bgGrey?: boolean;
  blueHeaderBg?: boolean;
};

export function Modal({
  isOpen,
  size,
  noPadding,
  lessHeaderPadding,
  onRequestClose,
  onAfterClose,
  header,
  children,
  footer,
  bgGrey,
  blueHeaderBg,
}: Props) {
  const height = useMotionValue(0);

  const { ref } = useResizeObserver({
    onResize: (rect) => {
      if (rect.height) {
        animate(height, rect.height);
      }
    },
  });

  useEffect(() => {
    let previousOverflow: string;
    if (isOpen) {
      // Prevent scrolling of body.
      // Keep a reference to the previous overflow value. When closing, try to
      // reset back to it. This allows opening multiple modals, and when the
      // last one closes we should restore the scroll
      previousOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isOpen) {
        document.body.style.overflow = previousOverflow ?? 'unset';
      }
    };
  }, [isOpen]);

  let width = 'max-w-[407px]';
  if (size === 'large') width = 'max-w-[800px]';
  if (size === 'medium') width = 'max-w-[518px]';

  return (
    <ReactModal
      isOpen={isOpen}
      parentSelector={() => {
        return document.getElementById('next-modals') ?? document.body;
      }}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      portalClassName="jernia-ui ReactModalPortal"
      overlayClassName={{
        base: `
            fixed
            z-30
            w-full
            h-full
            top-0
            left-0
            flex
            items-center
            justify-center
            bg-utility-backdrop
            transition-opacity
            duration-300
          `,
        afterOpen: isOpen ? 'opacity-100' : '',
        beforeClose: 'opacity-0',
      }}
      className={{
        base: `
            fixed
            max-h-full
            bg-white
            ${width}
            w-[calc(100%-1.5rem)]
            transform
            rounded
            outline-none
            transition-transform
            duration-300
          `,
        afterOpen: isOpen ? 'scale-100' : '',
        beforeClose: 'scale-90',
      }}
      closeTimeoutMS={300}
    >
      <motion.div className="overflow-hidden" style={{ height }}>
        <div ref={ref} className="calculated-dvh flex flex-col">
          <ModalHeader
            onClose={onRequestClose}
            bgGrey={bgGrey}
            blueHeaderBg={blueHeaderBg}
            lessHeaderPadding={lessHeaderPadding}
          >
            {header}
          </ModalHeader>
          <ModalBody
            noPadding={noPadding}
            bgGrey={bgGrey}
            hasFooter={Boolean(footer)}
          >
            {children}
          </ModalBody>
          {footer ? <ModalFooter bgGrey={bgGrey}>{footer}</ModalFooter> : null}
        </div>
      </motion.div>
    </ReactModal>
  );
}

function ModalHeader({
  children,
  onClose,
  bgGrey,
  blueHeaderBg,
  lessHeaderPadding,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  bgGrey?: boolean;
  blueHeaderBg?: boolean;
  lessHeaderPadding?: boolean;
}) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between border-neutral-500 rounded-t',
        !blueHeaderBg ? 'border-b' : '',
        lessHeaderPadding ? 'p-4' : 'px-8 py-4',
        bgGrey || blueHeaderBg ? 'bg-primary-100 border-neutral-500' : ''
      )}
    >
      <h3 className="text-1xl font-medium">{children}</h3>
      {onClose && (
        <button
          className="flex size-12 shrink-0 items-center justify-center rounded hover:bg-primary-300 focus:outline-none focus:ring"
          onClick={onClose}
        >
          <CloseRemoveXIcon />
        </button>
      )}
    </div>
  );
}

function ModalBody({
  children,
  noPadding,
  hasFooter,
  bgGrey,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  hasFooter: boolean;
  bgGrey?: boolean;
}) {
  return (
    <div
      className={classNames(
        'max-h-[80vh] overflow-y-auto rounded-b',
        hasFooter ? 'pb-4' : '',
        noPadding ? '' : 'p-4 md:p-8',
        bgGrey ? 'bg-neutral-300' : ''
      )}
    >
      {children}
    </div>
  );
}

function ModalFooter({
  bgGrey,
  children,
}: {
  children: React.ReactNode;
  bgGrey?: boolean;
}) {
  return (
    <div
      className={classNames('p-4 pt-4 md:p-8', bgGrey ? 'bg-neutral-300' : '')}
    >
      {children}
    </div>
  );
}
