import classNames from 'classnames';

type Props = {
  label?: React.ReactNode;
  subLabel?: string;
  checked?: boolean;
  variant?: 'default' | 'container';
  orientation?: 'horizontal' | 'vertical';
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export function RadioInput({
  label,
  subLabel,
  checked,
  onChange,
  className,
  variant = 'default',
  orientation = 'horizontal',
  ...rest
}: Props) {
  const radio = (
    <input
      type="radio"
      checked={checked}
      onChange={onChange}
      className={classNames(
        'form-radio peer h-6 w-6 cursor-pointer border border-neutral-800 p-2',
        'checked:animate-ripple-ring checked:border-transparent focus:border-transparent focus:ring',
        'relative z-10',
        'disabled:opacity-50 disabled:shadow-input-inner',
        variant === 'container'
          ? 'text-white checked:border-transparent'
          : 'text-primary',
        {
          'checked:bg-checkbox-inverted': variant === 'container',
        }
      )}
      {...rest}
    />
  );

  if (label) {
    return (
      <label
        className={classNames(
          className,
          'relative flex flex-grow cursor-pointer items-center',
          {
            'p-2': variant === 'container',
            'flex-col text-center px-4': orientation === 'vertical',
          }
        )}
      >
        {radio}
        <div
          className={classNames(
            'leading-tight relative z-10 peer-disabled:opacity-50',
            {
              'ml-4': orientation === 'horizontal',
              'mt-2': orientation === 'vertical',
              'peer-checked:text-primary': variant === 'default',
              'peer-checked:text-white': variant === 'container',
            }
          )}
        >
          <div className="font-semibold">{label}</div>
          {subLabel ? (
            <div
              className={classNames('text-xs mt-0.5', {
                'text-black': variant === 'default',
              })}
            >
              {subLabel}
            </div>
          ) : null}
        </div>

        {variant === 'container' ? (
          <div
            className={classNames(
              'absolute inset-0 peer-checked:bg-primary-800 border peer-checked:border-0 rounded',
              {}
            )}
          />
        ) : null}
      </label>
    );
  }

  return radio;
}
