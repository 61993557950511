import { useQuery } from 'react-query';

import { config } from '../lib/config';
import { fetchWithAuth } from '../state/fetch';
import type { JerniaProduct } from '../types/product';

export function useProducts(productIds: string[]): JerniaProduct[] {
  const queryInfo = useQuery(
    ['products', ...productIds],
    async () => {
      if (!productIds || productIds.length === 0) {
        return [];
      }

      const products = await Promise.all(
        productIds?.map(async (productCode) => {
          const product = await fetchWithAuth(
            `${config.apiBaseUrl}/products/${productCode}?fields=FULL`
          );

          if (!product.ok) {
            return null;
          }

          return product.json();
        })
      );

      return products?.filter((x) => x) ?? [];
    },
    {
      retry: 0,
    }
  );

  return queryInfo.isSuccess ? queryInfo.data : [];
}

export function useProductQuery(productId?: string) {
  return useQuery<unknown, unknown, JerniaProduct | null>(
    ['product', productId],
    async () => {
      const response = await fetch(
        `${config.apiBaseUrl}/products/${productId}`
      );

      if (!response.ok) {
        return null;
      }

      const result = await response.json();

      return result;
    },
    {
      retry: 0,
      enabled: typeof productId === 'string',
    }
  );
}

export function useProduct(productId: string): JerniaProduct | null {
  const products = useProducts([productId]);
  return products[0] || null;
}
